import { Engine, Render, World, Bodies, Body, Runner, MouseConstraint } from 'matter-js'

class Scene {
  private el: HTMLCanvasElement
  private airFriction: number = 0.03
  private resizeDelay: number = 400
  private resizeTimeout = null
  private mouseConstraint: MouseConstraint

  private viewportWidth: number = document.documentElement.clientWidth
  private viewportHeight: number = document.documentElement.clientHeight

  private engine: Engine
  private render: Render
  private runner: Runner
  private world: World

  constructor () {
    this.el = document.querySelector('#scene')

    this.setSize()
    this.initScene()
    this.initEvents()
  }

  setSize () {
    this.viewportWidth = document.documentElement.clientWidth
    this.viewportHeight = document.documentElement.clientHeight
  }

  initScene () {
    // engine
    this.engine = Engine.create() // handles the physics
    this.engine.world.gravity.y = 1

    // world
    this.world = this.engine.world // stores the bodies

    // render
    this.render = Render.create({
      canvas: this.el,
      engine: this.engine,
      options: {
        width: this.viewportWidth,
        height: this.viewportHeight,
        background: 'transparent',
        wireframes: false
      }
    })
    Render.run(this.render)

    // runner
    this.runner = Runner.create()
    Runner.run(this.runner, this.engine)

    this.createShapes()
    this.initWall()
  }

  random (range) {
    const [min, max] = range
    return Math.random() * (max - min) + min
  }

  rectangle (x, y, width, height) {
    return Bodies.rectangle(x, y, width, height, {
      isStatic: true,
      render: {
        visible: false
      }
    })
  }

  createShapes () {
    const shapes = []
    for (let i = 0; i < 15; i++) {
      const rnd = Math.random()
      const rnd2 = (Math.random() * 0.3 + 0.7) * 2

      let a, b
      if (rnd > 2 / 3) {
        b = Bodies.polygon(this.viewportWidth / 2, this.viewportHeight / 4, 4, rnd2 * 40 + 14, { render: { fillStyle: 'transparent' } })
        a = Bodies.polygon(this.viewportWidth / 2, this.viewportHeight / 4, 4, rnd2 * 40, { render: { fillStyle: '#000' } })
      } else if (rnd > 1 / 3) {
        b = Bodies.circle(this.viewportWidth / 2, this.viewportHeight / 4, rnd2 * 50 + 10, { render: { fillStyle: 'transparent' } })
        a = Bodies.circle(this.viewportWidth / 2, this.viewportHeight / 4, rnd2 * 50, { render: { fillStyle: '#000' } })
      } else {
        b = Bodies.polygon(this.viewportWidth / 2, this.viewportHeight / 4, 3, rnd2 * 50 + 20, { render: { fillStyle: 'transparent' } })
        a = Bodies.polygon(this.viewportWidth / 2, this.viewportHeight / 4, 3, rnd2 * 50, { render: { fillStyle: '#000' } })
      }

      a.collisionFilter.group = (i + 1) * -1
      b.collisionFilter.group = (i + 1) * -1

      const body = Body.create({
        parts: [b, a],
        friction: 0.002
      })

      shapes.push(body)
    }

    World.add(this.engine.world, shapes)
  }

  initWall () {
    World.add(this.engine.world, [
      this.rectangle(this.viewportWidth / 2, 0, this.viewportWidth, 24), // top
      this.rectangle(this.viewportWidth / 2, this.viewportHeight, this.viewportWidth, 24), // bottom
      this.rectangle(0, this.viewportHeight / 2, 24, this.viewportHeight), // left
      this.rectangle(this.viewportWidth, this.viewportHeight / 2, 24, this.viewportHeight) // right
    ])
  }

  initEvents () {
    this.addEventListeners()
    this.dragNDrop()
  }

  dragNDrop () {
    this.mouseConstraint = MouseConstraint.create(this.engine, {
      element: this.el,
      constraint: {
        render: {
          visible: false
        },
        stiffness: 0.6
      }
    })

    World.add(this.world, this.mouseConstraint)

    this.mouseConstraint.mouse.element.removeEventListener('mousewheel', this.mouseConstraint.mouse.mousewheel)
    this.mouseConstraint.mouse.element.removeEventListener('DOMMouseScroll', this.mouseConstraint.mouse.mousewheel)
  }

  shutdown () {
    Engine.clear(this.engine)
    Render.stop(this.render)
    Runner.stop(this.runner)
    this.removeEventListeners()
  }

  addEventListeners () {
    window.addEventListener('resize', this.onResizeThrottled.bind(this))
  }

  removeEventListeners () {
    window.removeEventListener('resize', this.onResizeThrottled)
  }

  onResizeThrottled () {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(this.onResize.bind(this), this.resizeDelay)
    }
  }

  onResize () {
    this.shutdown()
    this.initScene()
  }
}

window.addEventListener('DOMContentLoaded', () => {
  const scene = new Scene()
  console.log(scene)
})
